















export default

	props: block: Object

	computed:

		# Root classes
		classes: ->	[
			'text-' + @block.textAlignment.toLowerCase() if @block.textAlignment
			if @block.maxWidth then @block.maxWidth else 'max-w-copy'
		]

